.card-container {
	max-width: 300px;
}

.card-node-container {
	overflow-y: scroll;
	height: 400px;
	padding: "1vh 1vw";
}

.card-no-node-container {
	height: 77px;
}
